import React from "react";

function Modal({
  title, 
  closeModal,
  ...props}) {
  return (
    <div
      id="crud-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div className="relative w-full max-w-lg bg-white rounded shadow-lg">
        {/* Modal Header */}
        <div className="flex items-center justify-between px-4 py-3 border-b">
          <h5 className="text-xl font-semibold text-gray-800">{title}</h5>
          <button
            onClick={closeModal}
            type="button"
            className="btn-close text-gray-400 hover:text-gray-900 focus:outline-none"
            aria-label="Close"
          >
            <svg
              className="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        {/* Modal Body */}
        <div className="px-4 py-5">{props.children}</div>

        {/* Modal Footer */}
        {/* <div className="flex justify-end px-4 py-3 border-t">
          <button
            onClick={props.closeModal}
            className="px-4 py-2 text-white bg-gray-500 rounded hover:bg-gray-600 focus:outline-none"
          >
            Close
          </button>
        </div> */}
      </div>
    </div>
  );
}

export default Modal;
