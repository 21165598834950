import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useState } from "react";
import Sidebar from "../components/Sidebar";

export default function Admin() {
  const { token } = useSelector((state) => state.auth);
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(true);

  const handleIsExpanded = () => setIsExpanded(!isExpanded);

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace={true} />;
  }

  return (
    <div className="flex transition">
      <div className="relative">
        <Sidebar
          isExpanded={isExpanded}
          handleIsExpanded={handleIsExpanded}
        />
      </div>
      <div className="w-full p-10">
        <Outlet />
      </div>
    </div>
  );
}
