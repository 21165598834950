import { useEffect, useState } from "react";

const DropDown = ({
  name, 
  value = null,
  options, 
  optionValue, 
  optionLabel, 
  placeholder = "Select an option",
  ...props}) => {
  const [selected, setSelected] = useState(value);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const handleSelect = async (e) => {
    setLoading(true);
    const isResolved = await props.handleChange(e);
    if (isResolved) setLoading(false);
    setSelected(e?.target?.value);
  };

  return (
    <div className={props.container_classes}>
      {loading ? (
        <div className="animate-pulse text-md font-medium py-1 leading-6 text-center h-full">
          Processing...
        </div>
      ) : (
        <>
          {props.showLabel && (
            <label className="block text-sm font-medium leading-6 text-gray-900">
              {props.label}
            </label>
          )}
          <div className="rounded-md shadow-sm ring-1 ring-gray-300">
            <select
              value={selected}
              onChange={handleSelect}
              name={name}
              id={name}
              className="w-full border-0 px-1 py-2 bg-transparent text-gray-900 placeholder:text-gray-400 sm:text-sm"
            >
              {placeholder && <option value="">{placeholder}</option>}
              {options.map((option, index) => {
                return (
                  <option
                    value={option[optionValue || "value"]}
                    key={index}
                  >
                    {option[optionLabel || "label"]}
                  </option>
                );
              })}
            </select>
          </div>
        </>
      )}
    </div>
  );
};

export default DropDown;
