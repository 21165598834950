import React, { useState } from "react";
import { DateRangePicker, Calendar as Cal } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from "moment";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function DateRangeCalendar({ onSelect, start_date, end_date }) {
  const [isCalendarShowing, showCalendar] = useState(false);

  const selectionRange = {
    startDate: moment(start_date).toDate(),
    endDate: moment(end_date).toDate(),
    key: "selection",
  };

  
  return (
    <div className="relative ">
      <div className="flex items-center  border border-l-0 border-r-0 border-gray-300 rounded">
        {/* <FontAwesomeIcon
          className="p-2 border border-t-0 border-b-0 bg-white mr-2 hover:bg-gray-400 transition-all cursor-pointer"
          icon={faChevronLeft} */}
        {/* /> */}
        <span className="p-1 w-full border border-t-0 border-b-0 bg-white mr-2 hover:bg-gray-200 transition-all cursor-pointer" onClick={() => showCalendar(!isCalendarShowing)}>
          {start_date} / {end_date}
        </span>
        {/* <FontAwesomeIcon
          className="p-2 border border-t-0 border-b-0 bg-white ml-2 hover:bg-gray-400 transition-all cursor-pointer"
          icon={faChevronRight}
        /> */}
      </div>
      {isCalendarShowing && (
        <DateRangePicker
          className="absolute right-0 top-14 border border-gray-400 text-black bg-white z-40"
          ranges={[selectionRange]}
          dragSelectionEnabled={true}
          onChange={onSelect}
          showSelectionPreview={false}
          displayMode="dateRange"
          showDateDisplay={false}
          staticRanges={[]}
          staticRange=""
          inputRanges={[]}
          fixedHeight={true}
          moveRangeOnFirstSelection={true}
          editableDateInputs={true}
        />
      )}
    </div>

    //
  );
}
