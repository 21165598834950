import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const apiTokenUrls = process.env.REACT_APP_API_TOKEN_URL;


// Create an Axios instance with default configurations
const apiClient = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
});

// Add a request interceptor
apiClient.interceptors.request.use(
  (config) => {
    // Get the token from localStorage
    const token = localStorage.getItem('token');
    
    // If the token exists, set it in the Authorization header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Handle request error here
    return Promise.reject(error);
  }
);

// Function to get and store the CSRF token
export const getCsrfToken = async () => {
  try {
    const response = await apiClient.get(apiTokenUrls, { withCredentials: true });
    const token = response.data?.csrfToken; // Adjust according to your API response structure

    if (token) {
      localStorage.setItem('XSRF-TOKEN', token);
      apiClient.defaults.headers.common['X-CSRF-TOKEN'] = token; // Set as default header
    }
  } catch (error) {
    console.error('Error fetching CSRF token:', error);
  }
};

// Load CSRF token from localStorage if it exists
const initializeCsrfToken = () => {
  const token = localStorage.getItem('XSRF-TOKEN');
  if (token) {
    apiClient.defaults.headers.common['X-CSRF-TOKEN'] = token;
  } else {
    getCsrfToken();
  }
};

// Initialize CSRF token on startup
initializeCsrfToken();

export default apiClient;