import "./App.css";
import { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
// Components
import Layout from "./pages/Layout";
// Pages
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Admin from "./pages/Admin";
import Dashboard from "./pages/protected/Dashboard";
import DailyFigures from "./pages/protected/dailyfigures/DailyFigures";
import PendingFigures from "./pages/protected/dailyfigures/PendingFigures";
import EditFigure from "./pages/protected/dailyfigures/EditFigure";
import SingleFigure from "./pages/protected/dailyfigures/SingleFigure";
import AddBet from "./pages/protected/dailyfigures/AddBet";
import Handicappers from "./pages/protected/handicappers/Handicappers";
import HandicapperDetail from "./pages/protected/handicappers/HandicapperDetail";
import Insights from "./pages/protected/handicappers/Insights";
import Accounts from "./pages/protected/accounts/Accounts";

function App() {
  useEffect(() => {
    const abortController = new AbortController();
    return () => abortController.abort();
  }, []);

  return (
    <Routes>
    <Route path="/" element={<Layout />}>
      <Route path="login" element={<Login />} />
      <Route path="logout" element={<Logout />} />
      <Route path="admin" element={<Admin />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="daily-figures" element={<DailyFigures />} />
        <Route path="pending-figures" element={<PendingFigures />} />
        <Route path="edit-figure/:id" element={<EditFigure />} />
        <Route path="single-figure/:id" element={<SingleFigure />} />
        <Route path="handicappers" element={<Handicappers />} />
        <Route path="handicappers/insights" element={<Insights />} />
        <Route path="handicappers/capper-details/:id" element={<HandicapperDetail />} />
        <Route path="accounts" element={<Accounts />} />
        <Route path="add-bet" element={<AddBet />} />
      </Route>
      <Route path="" element={<Navigate to="/login" />} />
    </Route>
  </Routes>
  );
}

export default App;
