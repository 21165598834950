import React from 'react';

const Pagination = ({ pagination, onPageChange }) => {
  const {
    current_page: currentPage,
    last_page: totalPages,
    per_page: perPage,
    total,
  } = pagination;

  if (totalPages <= 1) return null;

  const startRecord = (currentPage - 1) * perPage + 1;
  const endRecord = Math.min(currentPage * perPage, total);

  const handlePageClick = (page) => {
    if (page !== currentPage && page > 0 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`pagination-button ${
            currentPage === i ? 'active' : ''
          }`}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  return (
    <div className="pagination-container">
      {/* Left Side: Display range and total records */}
      <div className="pagination-info">
        {`Showing ${startRecord}-${endRecord} of ${total} records`}
      </div>

      {/* Right Side: Pagination buttons */}
      <div className="pagination-buttons">
        <button
          className={`pagination-button ${
            currentPage === 1 ? 'active' : ''
          }`}
          disabled={currentPage === 1}
          onClick={() => handlePageClick(currentPage - 1)}
        >
          Prev
        </button>
        {renderPageNumbers()}
        <button
          className={`pagination-button ${
            currentPage === totalPages ? 'active' : ''
          }`}
          disabled={currentPage === totalPages}
          onClick={() => handlePageClick(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Pagination;
