import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
// Components
import CalendarButton from "../../../components/form/CalendarButton";
import DropDown from "../../../components/form/DropDown";
import TableChart from "../../../components/charts/TableChart";
// Store and Services
import {  getDailyFigureAccounts } from "../../../services/dailyFigureService";
import { fetchHandicapperInsights } from "../../../services/handicapperService";
import { getWeeksForChart, getWeeks } from "../../../utils/helpers";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function Insights() {
  const [selectedWeek, setSelectedWeek] = useState(0);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const { handicappers } = useSelector((state) => state.handicapper);

  const [chartData, setChartData] = useState(null);
  const [accounts, setAccounts] = useState([]);

  const dropdownWeeks = getWeeks();
  const chartWeeks = getWeeksForChart();
       
  const handleWeekChange = async (week) => {
    setSelectedWeek(week);
    return true;
  };

  useEffect(() => {
    fetchInsights();

    getDailyFigureAccounts().then((res) => {
      setAccounts(res.data.data);
    });
  }, [selectedAccount, selectedWeek]);

  const fetchInsights = () => {
    fetchHandicapperInsights(selectedWeek, selectedAccount).then((res) => {
      // const labels = res?.data.map((item) => item.handicapper_name);
      // const apiDynamic = res?.data.map((item) => item.count);

      // const data = {
      //   labels,
      //   datasets: [
      //     {
      //       label: "Handicappers by week",
      //       data: apiDynamic,
      //       backgroundColor: "#484ca3",
      //     },
      //   ],
      // };
      setChartData(res?.data);
    });
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
      },
    },
  };

  return (
    <div>
      <div className="flex justify-between">
        <h1 className="text-lg font-bold">Insights</h1>
        <div className="col-span-6 flex justify-end gap-2">
          <DropDown
            value={selectedAccount}
            value_is_key={true}
            showFirstOption={true}
            handleChange={(e) => {
              setSelectedAccount(e?.target?.value);
              return true;
            }}
            label="Select Account"
            name="account_id"
            options={accounts}
          />
          <CalendarButton
            btnText={"This week"}
            handleSubmit={() => handleWeekChange(0)}
            selected={selectedWeek == 0}
          />
          <CalendarButton
            btnText={"Last week"}
            handleSubmit={() => handleWeekChange(1)}
            selected={selectedWeek == 1}
          />
          <CalendarButton
            btnText={"Two weeks ago"}
            handleSubmit={() => handleWeekChange(2)}
            selected={selectedWeek == 2}
          />
          <DropDown
            value={selectedWeek}
            value_is_key={false}
            showFirstOption={true}
            handleChange={(e) => handleWeekChange(e.target.value)}
            label="Select Week"
            showlabel="false"
            name="account"
            options={dropdownWeeks}
          />
        </div>
      </div>
      <hr className="border-black border-2 mt-2" />
      {/* {chartData && <Bar options={options} data={chartData} />} */}
      <TableChart
        xAxisHeaders={handicappers}
        yAxisHeaders={chartWeeks}
        data={chartData}
      />
    </div>
  );                                                                                                                                                                                                       
}
