import apiClient from './apiClient';

export const getHandicappers = (params) => {
  return apiClient.get(`/handicapper`, {
    params: params,
  });
};

export const getActiveHandicappers = () => {
  return apiClient.get("/handicapper/active");
};

export const saveHandicapper = (data) => {
  return apiClient.post("/handicapper", data);
};

export const updateHandicapper = (data, id) => {
  return apiClient.put(`/handicapper/${id}`, data);
};

export const fetchHandicapperInsights = (week, account_id) => {
  const params = {week, account_id};
  return apiClient.get(
    `/handicapper/insights`, {
      params: params,
  });
};