
import { Link, useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartColumn,
  faMagic,
  faAdd,
  faSignOut,
  faUsers,
  faFileInvoice,
} from "@fortawesome/free-solid-svg-icons";

export default function Sidebar({ isExpanded }) {
  const appName = process.env.REACT_APP_NAME;
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  // Sidebar options defined within the component
  const sidebarOptions = [
    {
      icon: faChartColumn,
      title: "Figures",
      url: "/admin/daily-figures",
    },
    {
      icon: faFileInvoice,
      title: "Accounts",
      url: "/admin/accounts",
    },
    {
      icon: faUsers,
      title: "Handicappers",
      url: "/admin/handicappers",
    },
    {
      icon: faAdd,
      title: "Add New Bet",
      url: "/admin/add-bet",
    },
    {
      icon: faSignOut,
      title: "Logout",
      url: "/logout",
    },
  ];

  return (
    <motion.div
      animate={{
        width: isExpanded ? "180px" : "50px",
        transition: { ease: "easeInOut", duration: 0.5 },
      }}
      className="bg-primary overflow-hidden text-white h-full min-h-screen p-4"
    >
      <div className="flex justify-between items-center w-full">
        <motion.h1
          animate={{
            opacity: isExpanded ? "1" : "0",
            transition: { ease: "easeInOut", duration: 2, delay: 0.1 },
            transitionEnd: { display: isExpanded ? "block" : "none" },
          }}
        >
          <Link to="/admin/daily-figures">
                <svg className="logo-text cursor-pointer" viewBox="0 0 480 110">
                    <text y="50">{appName}</text>
                </svg>
          </Link>
        </motion.h1>
      </div>
      <ul className="mt-8">
        {sidebarOptions.map((option, index) => (
          <div
            key={index}
            className={`${
              currentPath.includes(option.url) ? "bg-white text-black font-bold" : ""
            }`}
          >
            <Link
              to={option.url}
              className="flex justify-between items-center gap-1 cursor-pointer hover:bg-blue-800 hover:text-white p-1"
            >
              <FontAwesomeIcon
                icon={option.icon}
                size={isExpanded ? "lg" : "xl"}
              />
              {isExpanded && <p className="text-left">{option.title}</p>}
            </Link>
            <hr
              className={`${isExpanded ? "border-gray-400" : "border-primary"}`}
            />
          </div>
        ))}
      </ul>
    </motion.div>
  );
}
