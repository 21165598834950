import { configureStore } from "@reduxjs/toolkit";
import auth from "./slices/authSlice";
import account from "./slices/accountSlice";
import handicapper from "./slices/handicapperSlice";
import dailyFigure from "./slices/dailyFigureSlice";
import dailyFigureSearch from "./slices/dailyFigureSearchSlice";

const store = configureStore({
  reducer: {
    auth: auth,
    account: account,
    handicapper: handicapper,
    dailyFigure: dailyFigure,
    dailyFigureSearch: dailyFigureSearch,
    
  },
});

export default store;
