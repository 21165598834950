import { Field } from "formik";

const Select = ({
  name,
  options,
  optionValue = "value",
  optionLabel = "label",
  placeholder = "Select an option",
  className = "form-select",
}) => (
  <Field as="select" name={name} className={className}>
    {placeholder && <option value="">{placeholder}</option>}
    {options.map((option) => (
      <option
        key={option[optionValue]}
        value={option[optionValue]}
      >
        {option[optionLabel]}
      </option>
    ))}
  </Field>
);

export default Select;
