import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getHandicappers } from '../../services/handicapperService';

// Async thunk for fetch accounts
export const fetchHandicappers = createAsyncThunk('handicapper/fetchHandicappers', async (_, thunkAPI) => {
  try {
      const state = thunkAPI.getState().handicapper;
      const { filters, page, sort_by, sort_order, per_page } = state;
      const params = { ...filters, page, sort_by, sort_order, per_page };
      const response = await getHandicappers(params);
      const data = response.data;
      return data;
  } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
  }
});

const initialState = {
  handicappers: [],
  pagination: {},
  loading: false,
  error: null,
  filters: {
      is_active: 1,
  },
  sort_by: 'id',
  sort_order: 'desc',
  page: 1,
  per_page: 10
};

const handicapperSlice = createSlice({
  name: "handicapper",
  initialState: initialState,
  reducers: {
    refresh(state) {
        Object.assign(state, initialState);
    },
    setFilters(state, action) {
        state.filters = { ...state.filters, ...action.payload };
        state.page = 1;
    },
    setSort(state, action) {
        const sort = action.payload;
        const [sort_by, sort_order] = sort.split('|');
        state.sort_by = sort_by;
        state.sort_order = sort_order;
    },
    setPage(state, action) {
        state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
      builder
          .addCase(fetchHandicappers.pending, (state) => {
              state.loading = true;
              state.error = null;
          })
          .addCase(fetchHandicappers.fulfilled, (state, action) => {   
              state.handicappers = action.payload.data;
              state.pagination = {
                  current_page: action.payload.current_page,
                  last_page: action.payload.last_page,
                  per_page: action.payload.per_page,
                  total: action.payload.total,
              };
              state.loading = false;
          })
          .addCase(fetchHandicappers.rejected, (state, action) => {
              state.loading = false;
              state.error = action.payload;
          });
  },
});

export const { refresh, setFilters, setSort, setPage } = handicapperSlice.actions;
export default handicapperSlice.reducer;
