import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../redux/slices/authSlice";
import { ReactComponent as LoginIcon } from "../assets/icons/login.svg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';

export default function Login() {
  const { token, loading, error } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) navigate("/admin/daily-figures", { replace: true });
  }, [token, navigate]);

  useEffect(() => {
    if (error) {
      const validationErrors = error.errors;
      for (const [field, messages] of Object.entries(validationErrors)) {
        // Show error messages for each field
        messages.forEach(message => toast.error(`${message}`));
      }
    }
  }, [error]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email format").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleLogin = (values) => {
      dispatch(loginUser(values));
  };

  return (
    <div className="grid grid-cols-6">
      <div className="col-span-2 flex flex-col gap-4 justify-center items-center">
        <h4 className="text-4xl">Welcome Back</h4>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={validationSchema}
          onSubmit={handleLogin}
        >
          {({ isSubmitting }) => (
            <Form className="w-8/12">
              
              <div className="mb-4">
                <Field
                  name="email"
                  placeholder="Enter Email"
                  label="Email"
                  type="email"
                  className="form-control"
                />
                <ErrorMessage name="email" component="div" className="form-error" />
              </div>
              
              <div className="mb-4">
                <Field
                  name="password"
                  placeholder="Enter Password"
                  label="Password"
                  type="password"
                  className="form-control"
                />
                <ErrorMessage name="password" component="div" className="form-error" />
              </div>

              <button type="submit" className="btn-success" isLoading={isSubmitting}>
                Login
              </button>
            </Form>
          )}
        </Formik>
      </div>
      <div className="flex justify-center col-span-4 bg-primary h-screen">
        <LoginIcon className="w-8/12" />
      </div>
    </div>
  );
}
