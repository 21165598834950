import { useEffect, useState } from "react";

export default function Dashboard() {

  return (
    <>
      <h1 className="text-2xl font-bold">Welcome to the Dashboard!</h1>
    </>
  );
}
