import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import InputLabel from "../../../components/form/InputLabel";
import DropDown from "../../../components/form/DropDown";
import { getDailyFigureById, updateDailyFigure } from "../../../services/dailyFigureService";
import { getHandicappers, getStatuses, getTeams } from "../../../utils/helpers";

let teams = getTeams();

export default function EditFigure() {
  const formRef = useRef(null);
  const handicappers = getHandicappers();
  const statuses = getStatuses();
  const [team1, setTeam1] = useState("");
  const [team2, setTeam2] = useState("");
  const [handicapper, setHandicapper] = useState("");
  const params = useParams();
  const [data, setData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    getDailyFigureById(params.id).then((res) => {
      setData(res.data.data);
      setTeam1(res.data.data?.team_1);
      setTeam2(res.data.data?.team_2);
      setHandicapper(res.data.data?.handicapper);
    });

  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formRef.current) {
      const formData = new FormData(formRef.current);
      let request = {};
      for (let [key, value] of Array.from(formData.entries())) {
        request[key] = value;
      }
      updateDailyFigure(request, params.id).then((res) => {
        if (res.data.status == "success")
          navigate("/dashboard/daily-figures", { replace: true });
      });
    }
  };

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit}
      className="space-y-10 divide-y divide-gray-900/10"
    >
      <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Scrapper Data
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            This information is scrapped from account using scrapper.
          </p>
        </div>

        <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-2 gap-x-6 gap-y-8 ">
              <InputLabel
                container_classes="col-span-1"
                label_title="Booking Ticket Id"
                input_id="book_ticket_id"
                input_name="book_ticket_id"
                input_type="text"
                value={data?.book_ticket_id}
              />

              <InputLabel
                container_classes="col-span-1"
                label_title="Game No"
                input_id="game_no"
                input_name="game_no"
                input_type="text"
                value={data?.game_no}
              />

              <DropDown
                container_classes="col-span-1"
                options={teams}
                handleChange={(e) => setTeam1(e.target.value)}
                label={`Select Team 1`}
                name="team_1"
                value_is_key={true}
                value={team1}
              />

              <DropDown
                container_classes="col-span-1"
                options={teams}
                handleChange={(e) => setTeam2(e.target.value)}
                label={`Select Team 2`}
                name="team_2"
                value_is_key={true}
                value={team2}
              />

              <InputLabel
                container_classes="col-span-1"
                label_title="Bet Type"
                input_id="bet_type"
                input_name="bet_type"
                input_type="text"
                value={data?.bet_type}
              />

              <InputLabel
                container_classes="col-span-1"
                label_title="Odds"
                input_id="odds"
                input_name="odds"
                input_type="text"
                value={data?.odds}
              />

              <InputLabel
                container_classes="col-span-1"
                label_title="Spread"
                input_id="spread"
                input_name="spread"
                input_type="text"
                value={data?.spread}
              />

              <InputLabel
                container_classes="col-span-1"
                label_title="Team Bet On"
                input_id="team_bet_on"
                input_name="team_bet_on"
                input_type="text"
                value={data?.team_bet_on}
              />

              <InputLabel
                container_classes="col-span-1"
                label_title="Risk"
                input_id="risk"
                input_name="risk"
                input_type="text"
                value={data?.risk}
              />

              <InputLabel
                container_classes="col-span-1"
                label_title="Win"
                input_id="win"
                input_name="win"
                input_type="text"
                value={data?.win}
              />

              <DropDown
                value={data?.status}
                container_classes="w-full flex flex-col"
                value_is_key={true}
                label=""
                name="status"
                options={statuses}
              />

              <InputLabel
                container_classes="col-span-1"
                label_title="Final Score"
                input_id="final_score"
                input_name="final_score"
                input_type="text"
                value={data?.final_score}
              />

              <InputLabel
                container_classes="col-span-1"
                label_title="Accepted At"
                input_id="accepted"
                input_name="accepted"
                input_type="text"
                value={data?.accepted}
              />

              <InputLabel
                container_classes="col-span-1"
                label_title="Placed On"
                input_id="placed_on"
                input_name="placed_on"
                input_type="text"
                value={data?.placed_on}
              />

              <InputLabel
                container_classes="col-span-1"
                label_title="Sports"
                input_id="sport"
                input_name="sport"
                input_type="text"
                value={data?.sport}
              />

              <InputLabel
                container_classes="col-span-1"
                label_title="Period"
                input_id="period"
                input_name="period"
                input_type="text"
                value={data?.period}
              />

              <InputLabel
                container_classes="col-span-1"
                label_title="Date Time"
                input_id="date"
                input_name="date"
                input_type="date"
                value={data?.date}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Custom Information
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            This information is custom information.
          </p>
        </div>

        <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-2 gap-x-6 gap-y-8 ">
              <InputLabel
                container_classes="col-span-1"
                label_title="Master Bet ID"
                input_id="date"
                input_name="master_bet_id"
                input_type="text"
                value={data?.master_bet_id}
              />

              <InputLabel
                container_classes="col-span-1"
                label_title="Worker"
                input_id="worker"
                input_name="worker"
                input_type="text"
                value={data?.worker}
              />

              <InputLabel
                container_classes="col-span-1"
                label_title="Worker Freeroll"
                input_id="worker_freeroll"
                input_name="worker_freeroll"
                input_type="text"
                value={data?.worker_freeroll}
              />

              <DropDown
                container_classes="col-span-1"
                options={handicappers}
                handleChange={(e) => setHandicapper(e.target.value)}
                label={`Select Handicapper`}
                name="handicapper"
                value_is_key={true}
                value={handicapper}
              />

              <InputLabel
                container_classes="col-span-1"
                label_title="Handicapper Risk"
                input_id="handicapper_risk"
                input_name="handicapper_risk"
                input_type="text"
                value={data?.handicapper_risk}
              />

              <InputLabel
                container_classes="col-span-1"
                label_title="Play Type"
                input_id="play_type"
                input_name="play_type"
                input_type="text"
                value={data?.play_type}
              />

              <InputLabel
                container_classes="col-span-1"
                label_title="Agent"
                input_id="agent"
                input_name="agent_id"
                input_type="text"
                value={data?.agent_id}
              />

              <InputLabel
                container_classes="col-span-1"
                label_title="Sub Agent"
                input_id="sub_agent"
                input_name="sub_agent_id"
                input_type="text"
                value={data?.sub_agent_id}
              />

              <InputLabel
                container_classes="col-span-1"
                label_title="Book Id"
                input_id="book_id"
                input_name="book_id"
                input_type="text"
                value={data?.book_id}
              />

              <InputLabel
                container_classes="col-span-1"
                label_title="Master Agent Risk"
                input_id="master_agent_risk"
                input_name="master_agent_risk"
                input_type="text"
                value={data?.master_agent_risk}
              />

              <InputLabel
                container_classes="col-span-1"
                label_title="Sub Agent Risk"
                input_id="sub_agent_risk"
                input_name="sub_agent_risk"
                input_type="text"
                value={data?.sub_agent_risk}
              />

              <InputLabel
                container_classes="col-span-1"
                label_title="Master Agent Free Roll"
                input_id="master_agent_freeroll"
                input_name="master_agent_freeroll"
                input_type="text"
                value={data?.master_agent_freeroll}
              />

              <div className="col-span-full">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Notes
                </label>
                <div className="flex rounded-md shadow-sm ring-1 ring-gray-300 sm:max-w-md">
                  <textarea
                    name="notes"
                    id="website"
                    className="block flex-1 border-0 p-2 bg-transparent text-gray-900 placeholder:text-gray-400  sm:text-sm "
                    value={data?.notes}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            <button
              type="button"
              className="text-sm font-semibold leading-6 text-gray-900"
              onClick={navigate("/dashboard/daily-figures")}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
