import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { updateHandicapper } from "../../../services/handicapperService";

function EditHandicapper({ closeModal, handicapper }) {
  // Validation schema for the form
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Name is required"),
    is_active: Yup.string().required("Status is required"),
  });

  // Initial values for the form
  const initialValues = {
    title: handicapper?.title || "",
    is_active: handicapper?.is_active?.toString() || "1", // Default to "Active"
  };

  // Form submission handler
  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    // console.log('form: ', values);
    
    await updateHandicapper(values, handicapper?.id).then((res) => {
      if (res.data.status === "success") {
        toast.success(res.data.message);
        closeModal();
      }
    }).catch((error) => {
      if (error.response && error.response.status === 422) {
        // Validation errors
        const validationErrors = error.response.data.errors;
        for (const [field, messages] of Object.entries(validationErrors)) {
          // Show error messages for each field
          messages.forEach(message => toast.error(`${message}`));
        }
      } else {
        // Other errors
        toast.error('An error occurred. Please try again.');
      }
     
    });

    setSubmitting(false); // Reset isSubmitting to false after submission
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="p-4 md:p-5">
          <div className="grid gap-4 mb-4 grid-cols-2">
            {/* Name Field */}
            <div className="col-span-1">
              <label
                htmlFor="title"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Name
              </label>
              <Field
                type="text"
                name="title"
                id="title"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="Type handicapper name"
              />
              <ErrorMessage name="title" component="div" className="form-error" />
            </div>

            {/* Status Field */}
            <div className="col-span-2 sm:col-span-1">
              <label
                htmlFor="is_active"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Status
              </label>
              <Field
                as="select"
                name="is_active"
                id="is_active"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
              >
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </Field>
              <ErrorMessage name="is_active" component="div" className="form-error" />
            </div>
          </div>

          {/* Submit Button */}
          <div className="w-full flex justify-end">
            <button
              type="submit"
              className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              disabled={isSubmitting}
            >
              <svg
                className="me-1 -ms-1 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              {isSubmitting ? "Saving..." : "Update Handicapper"}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default EditHandicapper;
