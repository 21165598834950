import React, { useEffect, useState } from "react";
import { useTransition } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faCheck,
  faEye,
  faPlus,
  faRepeat,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

// Components
import TableLoader from "../../../components/TableLoader";
import Pagination from "../../../components/Pagination";
import Modal from "../../../components/Modal";
import AddHandicapper from "./AddHandicapper";
import EditHandicapper from "./EditHandicapper";
// Store and Services
import { fetchHandicappers, setFilters, setPage } from "../../../redux/slices/handicapperSlice";


function Handicappers() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {handicappers, pagination, loading, filters, page} = useSelector((state) => state.handicapper);
  const [handicapper, setHandicapper] = useState(null);
  // const [status, setStatus] = useState(1);
  const [showAddModal, setAddModal] = useState(false);
  const [showEditModal, setEditModal] = useState(false);

  useEffect(() => {
    dispatch(fetchHandicappers());
  }, [dispatch, filters, page, showAddModal, showEditModal]);

  const searchHandicappers = (is_active) => {
    dispatch(setFilters({is_active}));
  };

  return (
    <>
      <div className="flex justify-between items-end">

        <h1 className="text-lg font-bold">Handicappers</h1>
        
        <div>
          <button
            onClick={() => setAddModal(true)}
            className="bg-primary text-white rounded p-2 mr-2 text-bold"
          >
            <FontAwesomeIcon className="mr-1" icon={faPlus} />
            Add Handicapper
          </button>
          <button
            onClick={() => navigate('insights')}
            className="bg-gray-500 text-white rounded p-2 text-bold"
          >
            <FontAwesomeIcon className="mr-1" icon={faChartLine} />
            Insights
          </button>
        </div>

      </div>
      
      <hr className="border-black border-2 mt-2" />

      <div className="flex justify-between  text-center bg-gray-50">
        <button
          onClick={() => searchHandicappers(1)}
          disabled={filters.is_active == "1"}
          className={`${
            filters.is_active == "1"
              ? "bg-orange-400 text-white"
              : "text-black hover:bg-orange-500"
          } w-6/12 p-2 font-bold cursor-pointer `}
        >
          Active
        </button>
        <button
          onClick={() => searchHandicappers(0)}
          disabled={filters.is_active == "0"}
          className={`${
            filters.is_active == "0"
              ? "bg-orange-400 text-white"
              : "text-black  hover:bg-orange-500"
          } w-6/12 p-2 font-bold cursor-pointer`}
        >
          In Active
        </button>
      </div>
      {loading ? (
          <TableLoader />    
      ) : (
        <>
        <table className="table-auto w-full border-collapse mt-8 text-sm">
          <thead className="table-header-group">
            <tr>
              <th>ID</th>
              <th className="whitespace-nowrap">Name</th>
              <th>Active Status</th>
              <th>Created At</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {handicappers?.map((item, index) => {
              return (
                <tr
                  key={index}
                  className="rounded-md text-center border border-b-1 border-t-0 border-r-0 border-l-0 py-4 cursor-pointer hover:bg-gray-200"
                  >
                  <td className="overflow-hidden py-4">{item?.id}</td>
                  <td className="overflow-hidden py-4">{item?.title}</td>
                  <td className="">
                    <ActiveStatus
                      value={item?.is_active}
                      index={index}
                      status={filters.is_active}
                    />
                  </td>
                  <td className="overflow-hidden py-4">
                    {moment(item?.created_at).format("LLLL")}
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        console.log(item)
                        setHandicapper(item); // Pass the selected handicapper
                        setEditModal(true);          // Open the edit modal
                      }}
                      className="bg-orange-400 text-orange-800 py-2 px-4 mb-2 rounded"
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination pagination={pagination} onPageChange={(page) => dispatch(setPage(page))} />
        </>
      )}

      {showAddModal && (
        <Modal title="Add Handicapper" closeModal={() => setAddModal(false)}>
          <AddHandicapper closeModal={() => setAddModal(false)} />
        </Modal>
      )}

      {showEditModal && (
        <Modal title="Edit Handicapper" closeModal={() => setEditModal(false)}>
          <EditHandicapper handicapper={handicapper} closeModal={() => setEditModal(false)} />
        </Modal>
      )}
     
    </>
  );

  


}

const ActiveStatus = (props) => {
  return (
    <>
      {props.editMode == props.index ? (
        <>
          <span
            className={`${
              props.status
                ? "bg-green-400 text-green-900"
                : "bg-red-400 text-red-900"
            } py-2 px-8 rounded-lg transition-all `}
          >
            {props.status ? "Active" : "Inactive"}
          </span>
          <FontAwesomeIcon
            className="px-2 hover:text-lg w-8 transition-all"
            size="1x"
            icon={faRepeat}
          />
        </>
      ) : (
        <span
          className={`${
            props?.value
              ? "bg-green-400 text-green-900"
              : "bg-red-400 text-red-900"
          } py-2 px-4 rounded-lg transition-all`}
        >
          {props?.value ? "Active" : "Inactive"}
        </span>
      )}
    </>
  );
};

export default Handicappers;
