import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import moment from "moment";
// Components
import Select from "../../../components/form/Select";
import TableLoader from "../../../components/TableLoader";
import Pagination from "../../../components/Pagination";
// Store and Services
import { getAccountUrls } from "../../../services/accountService";
import { fetchAccounts, refresh, setFilters, setPage } from "../../../redux/slices/accountSlice";

function Accounts() {
    const { accounts, pagination, loading, filters, page } = useSelector((state) => state.account);
    const [urls, setUrls] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        getAccountUrls().then((res) => {
          setUrls(res.data.data);
        });
    }, []);

    useEffect(() => {
        dispatch(fetchAccounts());
    }, [dispatch, filters, page]);

    const handleSearch = (values) => {
        dispatch(setFilters(values));
    };
    return (
        <>
            <h1 className="text-2xl font-bold">Accounts Listing Page</h1>
        </>
    );
    return (
        <>
        <div className="flex justify-between items-end">
            <h1 className="text-lg font-bold">Accounts</h1>
        </div>

        <hr className="border-black border-2 mt-2" />

        <Formik
            initialValues={filters}
            onSubmit={handleSearch}
        >
            {({ resetForm, submitForm }) => (
                <Form className="flex gap-4 my-4">
                    <Select 
                        name="url" 
                        options={urls}
                        optionValue="value"
                        optionLabel="label"
                        placeholder="Select URL"
                    />
                    <Field
                        name="account"
                        type="text"
                        placeholder="Account"
                        className="form-control"
                    />
                    <Field
                        name="label"
                        type="text"
                        placeholder="Label"
                        className="form-control"
                    />
                    <button type="submit" className="btn-primary">
                        Search
                    </button>
                    <button
                        type="button"
                        onClick={() => {
                            resetForm();
                            submitForm();
                        }}
                        className="btn-dark"
                    >
                        Reset
                    </button>
                </Form>
            )}
        </Formik>
        <hr className="border-black border-2 mt-2" />
        {loading ? (
            <TableLoader />    
        ) : (
            <>
            <table className="table-auto w-full border-collapse mt-8 text-sm">
                <thead className="table-header-group">
                    <tr>
                        <th>ID</th>
                        <th>Created At</th>
                        <th className="whitespace-nowrap">URL</th>
                        <th>Acccount</th>
                        <th>Label</th>
                        <th>Pending Wagers</th>
                        <th>Daily Figures</th>
                        <th>Last Alert</th>
                    </tr>
                </thead>
                <tbody>
                    {accounts?.map((item, index) => (
                        <tr
                            key={index}
                            className="rounded-md text-center border border-b-1 border-t-0 border-r-0 border-l-0 py-4 cursor-pointer hover:bg-gray-200"
                        >
                            <td className="overflow-hidden py-4">{item?.id}</td>
                            <td className="overflow-hidden py-4">
                                {moment(item?.created_at).format("MM/DD/YYYY hh:mm A")}
                            </td>
                            <td className="overflow-hidden py-4">{item?.url}</td>
                            <td className="overflow-hidden py-4">{item?.account}</td>
                            <td className="overflow-hidden py-4">{item?.label}</td>
                            <td className="overflow-hidden py-4">
                                <ActiveStatus
                                    value={item?.active_for_telegram}
                                    index={index}
                                />
                            </td>
                            <td className="">
                                <ActiveStatus
                                    value={item?.active_for_scrapper}
                                    index={index}
                                />
                            </td>
                            <td className="overflow-hidden py-4">
                                {moment(item?.last_pending_wager?.created_at).format("MM/DD/YYYY hh:mm A")}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagination pagination={pagination} onPageChange={(page) => dispatch(setPage(page))} />
            </>
        )}
        </>
    );
}

const ActiveStatus = (props) => (
    <>
    <span
        className={`${props?.value ? "bg-green-400 text-green-900" : "bg-red-400 text-red-900"} py-2 px-4 rounded-lg transition-all`}
    >
        {props?.value ? "Active" : "Inactive"}
    </span>
    </>
);

export default Accounts;
