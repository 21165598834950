import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getDailyFigures } from '../../services/dailyFigureService';
import moment from "moment";

// Async thunk for fetch accounts
export const fetchDailyFigures = createAsyncThunk('dailyFigures/fetchDailyFigures', async (_, thunkAPI) => {
    try {
        const state = thunkAPI.getState().dailyFigure;
        const { filters, page, sort_by, sort_order, per_page } = state;
        const params = { ...filters, page, sort_by, sort_order, per_page };
        const response = await getDailyFigures(params);
        const data = response.data;
        return data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

const getInitialFilters = () => {
    return {
        website: "",
        account_id: "",
        handicapper: "",
        week: 0,
        start_date: moment().startOf("isoWeek").format("YYYY-MM-DD"),
        end_date: moment().endOf("isoWeek").format("YYYY-MM-DD"),
    }
};

// Initial state
const initialState = {
    dailyFigures: [],
    pagination: {},
    loading: false,
    error: null,
    filters: getInitialFilters(),
    sort_by: 'id',
    sort_order: 'desc',
    page: 1,
    per_page: 10 
};

const dailyFigureSlice = createSlice({
    name: 'dailyFigure',
    initialState,
    reducers: {
        refresh(state) {
            Object.assign(state, initialState);
        },
        setFilters(state, action) {
            state.filters = { ...state.filters, ...action.payload };
            state.page = 1;
        },
        setSort(state, action) {
            const sort = action.payload;
            const [sort_by, sort_order] = sort.split('|');
            state.sort_by = sort_by;
            state.sort_order = sort_order;
        },
        setPage(state, action) {
            state.page = action.payload;
        },
        setWeek(state, action) {
            const week = action.payload;
            const start_date = moment()
            .subtract(week, "weeks")
            .startOf("isoWeek")
            .format("YYYY-MM-DD");
            const end_date = moment()
            .subtract(week, "weeks")
            .endOf("isoWeek")
            .format("YYYY-MM-DD");

            const filters = { ...state.filters, week, start_date, end_date };
            state.filters = filters
        }, 
        setDateRange(state, action) {
            const range = action.payload.selection;
            const start_date = moment(range.startDate).format("YYYY-MM-DD");
            const end_date = moment(range.endDate).format("YYYY-MM-DD");
            const filters = { ...state.filters, start_date, end_date };
            state.filters = filters
        },       

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDailyFigures.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDailyFigures.fulfilled, (state, action) => {
                state.dailyFigures = action.payload.data;
                state.pagination = {
                    current_page: action.payload.current_page,
                    last_page: action.payload.last_page,
                    per_page: action.payload.per_page,
                    total: action.payload.total,
                };
                state.loading = false;
            })
            .addCase(fetchDailyFigures.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { refresh, setFilters, setSort, setPage, setWeek, setDateRange } = dailyFigureSlice.actions;
export default dailyFigureSlice.reducer;


