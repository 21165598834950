const TableLoader = (props) => {

  return (
    <>
    <table className="table-auto  rounded w-full border-collapse mt-8 text-sm">
      <tbody>
        <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
          <td className="rounded-md"></td>
        </tr>
        <tr className="rounded-md border border-1 border-b-black h-10 bg-gray-200">
          <td className="rounded-md"></td>
        </tr>
        <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
          <td className="rounded-md"></td>
        </tr>
        <tr className="rounded-md border border-1 border-b-black h-10 bg-gray-200">
          <td className="rounded-md"></td>
        </tr>
        <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
          <td className="rounded-md"></td>
        </tr>
        <tr className="rounded-md border border-1 border-b-black h-10 bg-gray-200">
          <td className="rounded-md"></td>
        </tr>
        <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
          <td className="rounded-md"></td>
        </tr>
        <tr className="rounded-md border border-1 border-b-black h-10 bg-gray-200">
          <td className="rounded-md"></td>
        </tr>
        <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
          <td className="rounded-md"></td>
        </tr>
        <tr className="rounded-md border border-1 border-b-black h-10 bg-gray-200">
          <td className="rounded-md"></td>
        </tr>
        <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
          <td className="rounded-md"></td>
        </tr>
      </tbody>
    </table>
    </>
    
  );
}

export default TableLoader;
