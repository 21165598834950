import apiClient from './apiClient';

export const getAccounts = (params = {}) => {
  return apiClient.get(`/accounts`, {
    params: params,
  });
};

export const addAccount = (data) => {
  return apiClient.post("/accounts", data);
};

export const getAccountUrls = () => {
  return apiClient.get("/accounts/urls");
};
