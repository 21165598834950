import apiClient from './apiClient';

export const getDailyFigures = (params = {}) => {
  return apiClient.get("/daily-figure", {
    params: params,
  });
};

export const getDailyFigureById = (id = {}) => {
  return apiClient.get(`/daily-figure/${id}`);
};

export const saveDailyFigure = (data) => {
  return apiClient.post(`/daily-figure`, data);
};

export const updateDailyFigure = (data, id) => {
  return apiClient.put(`/daily-figure/${id}`, data);
};

export const getDailyFigureWebsites = () => {
  return apiClient.get("/daily-figure/websites");
};

export const getDailyFigureAccounts = () => {
  return apiClient.get("/daily-figure/accounts");
};

export const updateDailyFigureField = (data, id) => {
  return apiClient.put(`/daily-figure/${id}/update-field`, data);
};